import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the default styles
import ReCAPTCHA from 'react-google-recaptcha';

export default function NewsletterForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        treatment: '',
        source: '',
        recaptchaToken: ''
    });
    const [errors, setErrors] = useState({});
    const [countryCode, setCountryCode] = useState('gb'); // Default to 'us'
    const [captchaLoaded, setCaptchaLoaded] = useState(false); // Track if reCAPTCHA is loaded
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://ip-api.com/json/')
            .then(response => {
                const country = response.data.countryCode.toLowerCase();
                setCountryCode(country);
            })
            .catch(error => {
                console.error('Error fetching location data:', error);
            });

        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = () => setCaptchaLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
  
    const handlePhoneChange = (phone) => {
        setFormData({
            ...formData,
            phone: phone
        });

        if (phone.length < 9) {
            setErrors(prevErrors => ({
                ...prevErrors,
                phone: 'Phone number must be at least 9 digits long'
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.phone;
                return newErrors;
            });
        }
    };

    const handleRecaptchaChange = (token) => {
        setFormData({
            ...formData,
            recaptchaToken: token
        });
    };
 
    const validateForm = () => {
        const newErrors = {};
        for (const key in formData) {
            if (!formData[key] && key !== 'recaptchaToken') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        }
        if (!formData.recaptchaToken) {
            newErrors.recaptchaToken = 'Please verify that you are not a robot';
        }
        if (formData.phone.length < 9) {
            newErrors.phone = 'Phone number must be at least 9 digits long';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const formatPhoneNumber = (phone) => {
        if (phone && !phone.startsWith('+')) {
            return `+${phone}`;
        }
        return phone;
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validateForm()) {
            if (formData.phone.replace(/\D/g, '').length < 9) { 
                setErrors({ ...errors, phone: 'Phone number must be at least 9 digits' });
                return;
            }
    
            const formattedPhone = formatPhoneNumber(formData.phone);
            const dataToSend = { ...formData, phone: formattedPhone };
    
            axios.post('https://www.westernhealthturkey.com/dashboard/submit.php', dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log(response.data);
                navigate('/thank-you');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        }
    };
    
    const inputStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        boxSizing: 'border-box'
    };

    return (
        <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Name</label>
                        <input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            type="text"
                            style={inputStyle} // Applied inline styles
                            className="cs_form_field"
                            placeholder="Your Name"
                            required
                        />
                        {errors.name && <p className="error-text">{errors.name}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Email</label>
                        <input
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                            style={inputStyle} // Applied inline styles
                            className="cs_form_field"
                            placeholder="Your Email"
                            required
                        />
                        {errors.email && <p className="error-text">{errors.email}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Phone</label>
                        <PhoneInput
                            country={countryCode}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            inputStyle={inputStyle} // Applied inline styles
                            inputClass="cs_form_field"
                            required
                        />
                        {errors.phone && <p className="error-text">{errors.phone}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Treatment</label>
                        <select
                            className="cs_form_field"
                            id="treatment"
                            name="treatment"
                            value={formData.treatment}
                            onChange={handleChange}
                            style={inputStyle} // Applied inline styles
                            required
                        >
                            <option value="">Choose Treatment</option>
                            <option value="plastic_surgeries">Plastic Surgeries</option>
                            <option value="bariatric_surgeries">Bariatric Surgeries</option>
                            <option value="dental_treatments">Dental Treatments</option>
                            <option value="eye_surgeries">Eye Surgeries</option>
                        </select>
                        {errors.treatment && <p className="error-text">{errors.treatment}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">How did you hear about us?</label>
                        <select
                            className="cs_form_field"
                            id="source"
                            name="source"
                            value={formData.source}
                            onChange={handleChange}
                            style={inputStyle} // Applied inline styles
                            required
                        >
                            <option value="">Choose</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Google">Google</option>
                            <option value="Referral">Referral</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        {errors.source && <p className="error-text">{errors.source}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-12">
                        <ReCAPTCHA
                            sitekey="6LdmTzIqAAAAAOachFVNOYnK30jAi_12Dkl49yRf"
                            onChange={handleRecaptchaChange}
                            style={{ marginBottom: '10px' }}
                            required
                        />
                        {errors.recaptchaToken && <p className="error-text">{errors.recaptchaToken}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>
                    <div className="col-lg-12">
                        <div className="cs_height_18" />
                        <button type="submit" className="cs_btn" style={{ width: '100%', padding: '10px', fontSize: '16px' }}>
                            <span>Get a Free Quote</span>
                            <i>
                                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                            </i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
