import React from 'react';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import { pageTitle } from '../helpers/PageTitle';



const featureListData = [
  {
    title: '',
    subTitle: `
    <container>
     <h1>Thank You for Taking the First Step Towards Better Health!</h1>
     <p>We are currently processing your request and will soon connect you with our expert doctors for a preliminary assessment.</p>

      <p>Prepare Additional Medical Records: Please have any necessary medical records ready if requested.</p>

      <h3>Need Immediate Assistance?</h3>
      <p>Contact us at <a href="tel:+905431919209">[+90 543 191 92 09]</a> or email <a href="mailto:info@westernhealthturkey.com">[info@westernhealthturkey.com].</a></p>

   <p>Join Our Community</p>
    <p>Follow us on social media for news and promotions.</p>
    </container>
    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];




function ThankYou() {
  pageTitle('Thank You');
  return (
    <>
     
     
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="Thank You"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      
    </>
  );
}
export { ThankYou }