import React from 'react';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `
      <h3>Privacy Policy</h3>
<p>Last updated: February 16th 2021</p>

<p>WESTERN HEALTH MEDİKAL A.Ş.</p>

<p>Privacy Policy</p>

<p>Western Health operates the https://www.westernhealthturkey.com/ website, which provides the SERVICE.</p>

<p>“We” – Western Health private limited company registered as a medical tourism company in Turkey by Türsab. (Registration Number: 12706).</p>

<p>“Trading Name” – Western HEALTH MEDİKAL A.Ş.</p>

<p>“Service Provider”</p>

<p>The clinic or surgeon providing your medical procedure and care.</p>

<p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the westernhealthturkey.com website.</p>

<p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.v

<p>Information Collection and Use</p>
<p>While using our Service, we may request you to provide us with some personally identifying information, such as your name, phone number, and email address, in order to provide you with a better experience. We will use the information we collect to contact or identify you.</p>

<p>Log Data</p>
<p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

<p>Cookies
<p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>

<p>Our website uses these "cookies" to collect information and to improve our Service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>

<p>You can learn how to manage cookies on your web browser by following the Browser Cookies Guide.</p>

<p>Data Protection</p>
<p>We will hold your details, including your medical records, in the strictest of confidence. We will not disclose your data, records or information without your consent and will only share it with those concerned with your treatment.</p>

<p>Service Providers</p>
<p>We may employ third-party companies and individuals due to the following reasons:</p>

<p>To facilitate our Service;</p>

<p>To provide the Service on our behalf;</p>

<p>To perform Service-related services; or</p>

<p>To assist us in analyzing how our Service is used.</p>

<p>Security</p>
<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it.</p>

<p>Children’s Privacy</p>
<p>No one under the age of 18 is allowed to use our services. Personal identifiable information on children under the age of 18 is not knowingly collected by us. If we learn that a kid under the age of 18 has provided us with personal information, we erase it from our servers right away. If you are a parent or guardian and believe your child has provided us with personal information, please contact us so we can take the appropriate steps.</p>

<p>Changes to This Privacy Policy
<p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

<p>Contact Us</p>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us: info@westernhealthturkey.com</p>
 
    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];




function PrivacyPolicy() {
  pageTitle('Privacy Policy');
  return (
    <>
     
     
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="Privacy Policy"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      
    </>
  );
}
export { PrivacyPolicy }