import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
        <a href="tel:+905491989820">
          <IconBoxStyle11
            title="Phone"
            subTitle="+90 549 198 98 20"
            iconSrc="/images/contact/icon_1.svg"
          />
          </a>
        </div>
        <div className="col-xl-4">
          <a href="mailto:info@westernhealthturkey.com">
          <IconBoxStyle11
            title="Email"
            subTitle="info@westernhealthturkey.com"
            iconSrc="/images/contact/icon_2.svg"
          />
          </a>
        </div>
        <div className="col-xl-4">
        
          <IconBoxStyle11
            title="Adress"
            subTitle="Folkart Time, Kazım Dirik Mah Ankara Cad. 296 Sok. No:8 1.Blok K:3 Daire:306, 35080 Bornova/İzmir
"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3124.7404642305437!2d27.18281587716716!3d38.447463373124755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b962bab2b456af%3A0xa2b435f09cc0ef66!2sWestern%20Health%20Turkey!5e0!3m2!1str!2str!4v1720463449710!5m2!1str!2str"
          allowFullScreen
        />
      </div>
      {/* End Google Map */}
    </div>
  );
}
