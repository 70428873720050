import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
    <li>
      <i className="cs_accent_bg">
        <Icon icon="ep:location" />
      </i>
      Folkart Time, Kazım Dirik Mah Ankara Cad. 296 Sok. No:8 1.Blok K:3 Daire:306, 35080 Bornova/Izmir
    </li>
    <li>
      <i className="cs_accent_bg">
        <Icon icon="fluent:call-24-regular" />
      </i>
      <a href="tel:+905491989820">+90 549 198 98 20</a>
    </li>
    <li>
      <i className="cs_accent_bg">
        <Icon icon="bi:envelope" />
      </i>
      <a href="mailto:info@westernhealthturkey.com">info@westernhealthturkey.com</a>
    </li>
  </ul>
  
  );
}
