import React from 'react';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';
import ContactForm from '../components/ContactForm';
import Section from '../components/Section';
import ContactInfoSection from '../components/Section/ContactInfoSection';
import { pageTitle } from '../helpers/PageTitle';

function Contact() {
  pageTitle('Contact');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/contact.jpg"
        title="Contact Us"
        subTitle="We are at your service 24/7"
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Contact Us" />
      </Section>
    </>
  );
}
export { Contact }