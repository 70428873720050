import React from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import FunFactSection from '../components/Section/FunFactSection';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `
<strong>First Contact</strong>
<p>When you contact us via our website or social media platforms, we promptly schedule a meeting with you, preferably online owing to current circumstances, to explain our services, processes, and rates in general. If you decide to go ahead, we'll set up a video consultation with the doctor who will be doing your medical treatment in Turkey. Your doctor may request some tests, x-rays, and a medical history form before your video consultation in order to properly analyze your health and design the most individualized treatment plan for you.</p>

<strong>Consultation with Your Doctor</strong>
<p>We introduce you to your doctor in this online consultation and provide you the time and chance to ask your doctor any questions you have about your treatment. You and your doctor will review treatment options during your video consultation and decide the best option for you interactively</p>

<strong>Price Offer & Payment</strong>
<p>We generate your price offer based on your treatment information after the online consultation and convey it to you in written within 24 hours. If you accept our offer, you will be required to sign an agreement outlining all of the services we will provide you, besides your and our duties within your medical travel to Turkey. You must make a deposit payment of 10% of your total offer. The deposit can be paid before your trip to Turkey. You can select the payment option that best suits your needs.</p>

<strong>Travel Arrangements</strong>
<p>After we receive your payments, all you have to do is book your flights and provide us with the necessary information. Then, we'll take care of the rest! All services, including airport transfers, hotel accommodations, local transports between hotel-clinic/hospital, and a patient representative assigned to you, are organized and booked by us.</p>

<strong>Airport & Hotel Pickup</strong>
<p>When you arrive, a VIP transfer will pick you up from the airport and transport you to your hotel. On the day(s) of your treatment, your patient representative will pick you up from your hotel and transport you to our partner clinic or hospital, depending on the treatment you will receive.</p>

<strong>Consultation & Treatment</strong>
<p>Your surgeon will inspect your body in depth during your consultation before treatment, noting some places to be treated and educating you about the treatment procedures. Your doctor will also describe any potential risks or complications, and you will have the opportunity to ask about any concerns you may have about your therapy.</p>

<p>Your treatment will be administered by your doctor in one of our partner clinics or hospitals. If you are having surgery, you will be required to stay in the hospital for at least one night. You will be discharged after a few hours of rest if you have a non-surgical procedure. In either instance, your patient representative will pick you up from our partner clinic/hospital and transport you to your destination.</p>

<strong>Checkup/s & Fit to Fly</strong>
<p>Before returning home, you will need to see your doctor for one or more checkups after your treatment. Your doctor will schedule your checkups following your treatment, and your patient representative will accompany you to them.</p>

<p>Your doctor will give you your "Fit to Fly" report at your last exam, and once you have it, you are set to travel home!</p>

<strong>Airport Drop Off</strong>
<p>On the day of your departure, a VIP transfer will pick you up from your hotel and transport you to the airport.</p>

<strong>Routine Follow-Ups</strong>
<p>We call you regularly when you return home to check on your healing.</p>

<strong>Minor/Major Aftercare</strong>
<p>After you return home, we'll keep in touch with you to ensure that you are happy with the treatment. You can always contact us if you have a minor or major problem with your therapy, though these types of situations occur very rarely.</p>
    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];



function HowDoesitWork() {
  pageTitle('HowDoesitWork');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pages/how-does-it-work.jpg"
        title="How Does it Work"
        subTitle="Western Health Turkey"
      />
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="How Does it Work"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
    
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}

export { HowDoesitWork }