import React from 'react';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `
     <h3>Terms and Conditions</h3>
<p> MEDICAL SERVICES BROKERAGE AGREEMENT</p>
<p>This agreement set out the terms and conditions about any medical package you buy from Western Health.</p>

<p>Please read these Terms & Conditions and the documents referred below. By accepting our terms and conditions, you confirm that you will be bounded with these Terms & Conditions and you agree to comply with them.</p>
</p>
<p>Western Health has right to change and modify the Terms and Conditions without any prior notice.</p>

<p>1. PARTIES</p>
<p>This Medical Service Brokerage Agreement (“Agreement”) has been executed on the date of signature by and between Western Health Medikal A.Ş. (“Western Health”) and the undersigned Patient (the Patient) with the free will of the parties. With the acceptance of the Agreement, the Patient hereby accepts, declares and undertakes that he/she understands these rules and will comply with them.
</p>
<p>Western Health has the right to amend this Agreement when necessary.</p>

<p>2. SUBJECT OF THE AGREEMENT
<p>2.1 This agreement regulates medical tourism in Turkey which Western Health serves as an intermediary company in accordance with the Patient’s request and the arrangement of all kinds of services necessary in this context such as medical attendants, hospital, accommodation, travel and transportation; price and payment basis of these services and the rights and obligations of the parties. Western Health is not a service provider, but an institution which serves as an intermediary to reach service providers.
</p>
<p>2.2 In accordance with the Agreement, the Patient accepts, declares and undertakes that he/she has been provided preliminary information regards to the medical tourism, which will be given to him/her as an intermediary institution, by Western Health and that he/she will comply with this Agreement in accordance with the information provided.
</p>
<p>2.3 The Patient acknowledges that the service provided by Western Health is an intermediary service, and agrees and undertakes that he/she knows that the party for any potential dispute is the actual service provider for the disruptions during the intermediary services provided under this Agreement such as hospital, medical attendant, transfer, accommodation.
</p>
<p>2.4 Within the scope of the Agreement, the Patient accepts, declares and undertakes that the Patient gave his/her personal identity and contact information correctly, that he/she does not have any health and/or travel obstacles, that, in the event of giving incorrect information, Western Health does not have any obligation or liability, that, if Western Health suffers any loss due to the provision of incorrect information, he/she will cover the loss immediately and without delay, and that he/she can be reached via the contact information given. The Patient is personally liable for the missing information and documents provided by him/her.
</p>
<p>2.5 In line with the information and details given to him/her by Western Health, in cases where the patient is late or misses the transportation facilities, all additional costs will be covered by the Patient.
</p>
<p>3. AGREEMENT PRICE AND METHOD OF PAYMENT</p>
<p>3.1 In return for the intermediary service to be provided to the Patient by Western Health, the Patient must first make a 10% prepayment. With this prepayment, the day of the necessary arrangements for the Patient is reserved as guaranteed from the company and/or persons who will provide health, accommodation and transfer services on behalf of the Patient. Signing this Agreement without making any prepayment does not create any liability for Western Health.
</p>
<p>3.2 Services which are not included in this Agreement or which will be performed after the patient been has provided with medical intermediary services shall also be subject to additional charges. All fees arising from the Agreement and similar payments and expenses are charged to the Patient. In case of default, default interest payment will be requested.
</p>
<p>3.3 The prepayment of %10 mentioned above is not refundable unless the patient sends a medical report indicating he/she is not medically suitable for the treatment the parties agreed on before. The prepayment can be transferred to another person upon the approval of every parties involved.
</p>
<p>3.4 Balance payment concerning all arrangements subject to intermediary service is payable to Western Health until the day of operation. Once the reservation is made, there will be no refund of the fee if the Patient refrain from signing any contracts or documents submitted by service providers, fail to fulfil his/her obligations agreed and signed, and in any similar cases. If the patient cause additional cost to Western Health in case he/she cannot benefit from travel, accommodation and transportation or health services on the dates agreed in the Agreement, the additional cost in question will be paid to Western Health immediately by the patient.
</p>
<p>4. RIGHTS AND OBLIGATIONS OF Western Health</p>
<p>4.1 Western Health will act as an agency for bariatric, aesthetic, dental, hair transplantation and general surgery or general healthcare services, accommodation, travel and transportation services which will be provided to the Patient under this Agreement and will direct the Patient to the clinic and/or to the medical attendant where the healthcare will be provided.
</p>
<p>4.2 The Patient irrevocably agrees, declares and undertakes that Western Health has no legal obligation, except intermediary service, for medical services that will be provided to the Patient and that he/she will hold Western Health harmless of any and all pecuniary or non-pecuniary damages arising from medical services. The Patient agrees, declares and undertakes that he/she will get all these services from Western Health as an intermediary institution.
</p>
<p>4.3 The Patient cannot take actions incompatible with this article, the Patient accepts, declares and undertakes that service provider is liable for medical services and additional services which are not under the liability of Western Health. The Patient cannot take any negative action against Western Health because of his/her unsatisfaction with medical services provided, otherwise the Patient will be under obligation to pay, as a penalty, the amount of remuneration paid.
</p>
<p>4.4 Western Health advises the Patient to insure the risks of any accident, all kinds of damages arising from an accident and expenses of treatment before this Agreement enters into force.
</p>
<p>4.5 Western Health cannot be held liable for the damages arising from nonperformance of the Agreement at all or as is due because of force major or unexpected events. Force major events provided by law, events that constitutes an obstacle for the initiation, continuation or termination of the Agreement which are extraordinary and unpredictable even if all the measures are taken and the events that Western Health does not have any impact on will be regarded as force major.
</p>
<p>5. RIGHTS AND OBLIGATIONS OF THE PATIENT</p>
<p>5.1 Western Health, as an agency, will provide medical service, travel, accommodation and transportation services to the Patient.</p>
</p>
<p>5.2 The Patient is obliged to inform Western Health of his/her medical background correctly and clearly by filling the medical form that is available on the website with his/her health information.</p>
</p>
<p>5.3 The Patient is obliged to make payment as specified in the Agreement. The Patient is personally liable for any delay caused by him/her. If such delay causes Western Health any additional cost or obligation, the Patient must pay these costs and expenses to Western Health.</p>
</p>
<p>5.4 If the Patient is under 18 years old, it is obligatory to provide Western Health with the contact information which can be used for direct communication with legal representative of minor or minor.</p>
</p>
<p>5.5 The Patient is obliged to cover the expenses in case of a free-of-charge transfer to a facility agreed over Western Health and compulsory accommodation.</p>
</p>
<p>5.6 By accepting this Agreement, the Patient agrees, declares and undertakes not to attack or criticise Western Health or any of its employee, associate or partner publicly (review websites, social media networks, blogs, public forums etc.) in regards to Service Provider’s treatments and actions. As Western Health cannot be held responsible for the services, medical treatments and procedures provided by the Service Provider, the Patient agrees, declares and undertakes not to bring bad name to Western Health or any of its employees or take action that negatively affects Western Health, its reputation, services or management at any time during or subsequent to contract period. In case of breach of this clause, the Patient agrees, declares and undertakes to remove and take down the content immediately upon Western Health’ assessment. If content remains, in part or in whole, the Patient agrees, declares and undertakes to pay 10.000,00 GBP to Western Health as a penalty in addition to all damages.</p>
</p>
<p>6. CANCELLATION AND ASSIGNMENT OF THE AGREEMENT</p>
<p>6.1 Western Health has the right to cancel this Agreement unilaterally in line with the goodwill. In case of cancellation of the Agreement by Western Health, the Patient will get a refund for the amount he/she paid except for compulsory taxes, fees and similar expenses arising from the legal obligations and the expenses that have been paid to third parties, and can be documented and cannot be refunded. Again in this context, the cancellation and change rules of the companies related to the flight, railway and navigation tickets and accommodation expenses purchased previously shall be applied as they are. If any, penalties shall be reflected to the passenger, namely the patient.</p>

<p>6.2 The Patient will cover the bank transfer fee for refunds that will be made through a bank. Western Health does not have a right for compensation for this. Western Health cannot be held liable for any delay of refund of fees paid by credit card.</p>

<p>6.3 If one of the parties violates the Agreement, non-breaching party requests for elimination of the breach of the Agreement within <p>7 (seven) work days by a written notice via notary public. Non-breaching party has the right to cancel the Agreement if the breach cannot be eliminated within the given period of time.</p>

<p>6.4 For the performance of the Agreement, Western Health has the right to assign the Agreement partially or fully to real and legal <p>persons with whom it is contracted. In such case, the successor for the assigned parts is the related real person or legal entity.</p>

<p>7. CONFIDENTIALITY AGREEMENT
<p>7.1 “Confidential Information” that can be exchanged between parties means any and all information of the Party owning them, which constitutes a trade secret and/or which is under possession of the said party; and it includes, without any limitation, design information, technical information, trade secrets, ideas and inventions, projects, drawings, models, software programs, algorithms, software modules, program source code, specifications, product plans and technologies, software user manuals, marketing information, Patient lists, estimations and evaluations, financial reports, contract terms, records and all information and materials related to aforesaid Party’s business, all kinds of products, goods and services related to itself, its shareholders, affiliates, other persons licensed by itself, its Patients and consultants, method used to obtain these, trade secret, any kind of formula, know-how, patent, invention, design, Patient lists, budget, business development, marketing and pricing plans and strategies and all similar information.</p>

<p>7.2 Western Health and the Patient accept and declare that the information provided to them is confidential, they will keep this <p>information confidential in accordance with Personal Data Protection Law, and will not share the information with any organization or institution without the approval of the other Party. Western Health is not under any legal obligation or liability due to the sharing of information in cases where it is a legal obligation to share information in accordance with legal regulations or in cases where it is urgent for the performance of this Agreement.</p>

<p>7.3 The Patient cannot make adjustments on his/her behalf or on behalf of other person by bypassing Western Health and directly <p>contacting with persons and companies from which he/she receives medical service. The Patient cannot disclose text messages, calls, conversations and images between him/her and Western Health on any platform and anywhere without written permission. Otherwise, the Patient accepts, declares and undertakes that he/she is obliged to pay 10.000,00 GBP as a penalty clause in cash and at once in addition to all damages.</p>

<p>8. OTHER TERMS
<p>8.1 Children who are 0-6 years old are not given separate beds for hotel accommodations.</p>

<p>8.2 Since there is a seat requirement in means of transportation for children who are over 2 years old, they are subject to fee.</p>

<p>8.3 The Patient has obligation to follow and control his/her belongings during the service period, and Western Health is not liable for lost/stolen/damaged belongings. Western Health is not liable for belongings lost and events experienced in third parties such as hotel, transfer.</p>

<p>8.4 Western Health reserves the right to make any adjustments or to cancel in its program, accommodation facilities and means of transportation due to force major or operational reasons. In such case, reservation owner’s approval is not necessary and he/she does not have the right to cancel or compensation.</p>

<p>8.5 The Patient is specifically warned and informed during the Agreement that departure time or the type of plane may change due to reasons arising from the airline company when traveling by airway, and this has been accepted by the Patient.</p>

<p>8.6 The accommodation facility establishes blockage. A passport and depending on the state a visa is required, including babies, for foreign trips. The Patient accepts and declares that he/she is informed by Western Health about this issue.</p>

<p>8.7 All additional service fees other than the type of accommodation specified within the scope of the Agreement shall be paid by the Patient in cash during the check-out from the hotel.</p>

<p>8.8 It is compulsory to be present at the meeting point half an hour before for road transport and two hours before for airway transport.</p>

<p>8.9 Seat number will not be given within the scope of execution of the Agreement including transportation. Seat number shall be learned from the transfer guide in the vehicle during the departure.</p>

<p>8.10 Pets are not allowed in the means of transport and in the facility.</p>

<p>9. APPLICABLE LAW AND JURISDICTION</p>
<p>9.1 This Agreement is subject to the Turkish law. Istanbul Courts and Execution Offices are authorized to resolve the disputes arising from this Agreement.</p>

<p>9.2 Any change in meaning or contradiction between the provisions of this Agreement does not invalidate any other provision. The Parties are responsible for stamp duty for the copy of this Agreement which they possess.</p>

<p>10. EVIDENTIAL AGREEMENT</p>
<p>10.1 The Patient accepts, declares and undertakes that Western Health’ records and books will be evidence for the disputes arising from this Agreement, that he/she will not object to these record and books and will not present any other evidence.</p>
    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];




function TermsAndConditions() {
  pageTitle('Terms And Conditions');
  return (
    <>
     
     
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="Terms And Conditions"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      
    </>
  );
}
export { TermsAndConditions }