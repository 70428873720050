import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the default styles
import ReCAPTCHA from 'react-google-recaptcha';

export default function AppointmentFormStyle2() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        treatment: '',
        source: '',
        recaptchaToken: ''
    });
    const [errors, setErrors] = useState({});
    const [countryCode, setCountryCode] = useState('gb'); // Default to 'us'
    const [captchaLoaded, setCaptchaLoaded] = useState(false); // Track if reCAPTCHA is loaded
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the user's country code based on their IP using ip-api.com
        axios.get('http://ip-api.com/json/')
            .then(response => {
                const country = response.data.countryCode.toLowerCase();
                setCountryCode(country);
            })
            .catch(error => {
                console.error('Error fetching location data:', error);
            });

        // Load reCAPTCHA script
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = () => setCaptchaLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handlePhoneChange = (phone) => {
        setFormData({
            ...formData,
            phone: phone
        });
    };

    const handleRecaptchaChange = (token) => {
        setFormData({
            ...formData,
            recaptchaToken: token
        });
    };

    const validateForm = () => {
        const newErrors = {};
    
        // Diğer alanların kontrolü
        for (const key in formData) {
            if (!formData[key] && key !== 'recaptchaToken' && key !== 'phone') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        }
    
        // Telefon numarası kontrolü
        const phoneWithoutCountryCode = formData.phone.replace(`+${countryCode}`, '').trim();
        if (!formData.phone) {
            newErrors.phone = 'Phone is required';
        } else if (phoneWithoutCountryCode.length === 0) {
            newErrors.phone = 'Please enter a valid phone number';
        }
    
        // reCAPTCHA kontrolü
        if (!formData.recaptchaToken) {
            newErrors.recaptchaToken = 'Please verify that you are not a robot';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    
    const formatPhoneNumber = (phone) => {
        // Ensure the phone number starts with '+'
        if (phone && !phone.startsWith('+')) {
            return `+${phone}`;
        }
        return phone;
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validateForm()) {
            // Eklenen: phone uzunluğunu kontrol eden if bloğu
            if (formData.phone.replace(/\D/g, '').length < 9) { 
                setErrors({ ...errors, phone: 'Phone number must be at least 9 digits' });
                return;
            }
    
            const formattedPhone = formatPhoneNumber(formData.phone);
            const dataToSend = { ...formData, phone: formattedPhone };
    
            axios.post('https://www.westernhealthturkey.com/dashboard/submit.php', dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log(response.data);
                navigate('/thank-you');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        }
    };
    

    return (
        <div className="cs_appointment_form cs_style_1 cs_type_1 cs_fs_20 position-relative">
            <form onSubmit={handleSubmit}>
                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        <input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            type="text"
                            className="cs_form_field"
                            placeholder="Your Name"
                            required
                        />
                        {errors.name && <p className="error-text">{errors.name}</p>}
                    </div>
                </div>

                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        <input
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                            className="cs_form_field"
                            placeholder="Your Email"
                            required
                        />
                        {errors.email && <p className="error-text">{errors.email}</p>}
                    </div>
                </div>

                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        <PhoneInput
                            country={countryCode}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            inputStyle={{ paddingLeft: '55px' }} // Add left padding
                            containerStyle={{ marginBottom: '10px' }} // Optional: Add margin if needed
                            buttonStyle={{ paddingLeft: '12px' }} // Optional: Adjust padding for the button
                            inputClass="cs_form_field" // Keep the input styling
                            required
                        />
                        {errors.phone && <p className="error-text">{errors.phone}</p>}
                    </div>
                </div>

                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        <select
                            className="cs_form_field"
                            id="treatment"
                            name="treatment"
                            value={formData.treatment}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Choose Treatment</option>
                            <option value="plastic_surgeries">Plastic Surgeries</option>
                            <option value="bariatric_surgeries">Bariatric Surgeries</option>
                            <option value="dental_treatments">Dental Treatments</option>
                            <option value="eye_surgeries">Eye Surgeries</option>
                        </select>
                        {errors.treatment && <p className="error-text">{errors.treatment}</p>}
                    </div>
                </div>

                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        <select
                            className="cs_form_field"
                            id="source"
                            name="source"
                            value={formData.source}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Heard about us</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Google">Google</option>
                            <option value="Referral">Referral</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        {errors.source && <p className="error-text">{errors.source}</p>}
                    </div>
                </div>

                <div className="cs_appointment_form_field">
                    <div className="cs_appointment_form_field_right">
                        {captchaLoaded && (
                            <ReCAPTCHA
                                sitekey="6LdmTzIqAAAAAOachFVNOYnK30jAi_12Dkl49yRf"
                                onChange={handleRecaptchaChange}
                                required
                            />
                        )}
                        {errors.recaptchaToken && <p className="error-text">{errors.recaptchaToken}</p>}
                    </div>
                </div>

                <button type="submit" className="cs_btn cs_style_1 w-100">
                    <span>Get a Free Quote</span>
                    <i>
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                        <img src="/images/icons/arrow_white.svg" alt="Icon" />
                    </i>
                </button>
            </form>
        </div>
    );
}
