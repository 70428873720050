import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Section from '../components/Section';
import Breadcrumb from '../components/Breadcrumb';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../components/Spacing';
import { blogData } from '../detailTreatmentData';




function BlogDetails() {
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      const locationPathname = location.pathname;
      const basePath = "/blog/";
      const remainingPath = locationPathname.slice(basePath.length);

      const filterArray = blogData.filter((item) => item.href === remainingPath);
      setData(filterArray.length ? filterArray : []);
    };

    fetchData();
  }, [location.pathname]);

  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={data.length > 0 ? data[0].title : ""} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
          
            <div className="cs_blog_details_date">
              {data.length > 0 ? data[0].date : "Date Not Available"} | {data.length > 0 ? data[0].author : "Author Not Available"}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={data.length > 0 ? data[0].fullImageUrl : "/images/blog/blog_details_1.jpeg"}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-12">
            <div className="cs_blog_details">
              <div dangerouslySetInnerHTML={{ __html: data.length > 0 ? data[0].text : "" }} />
            </div>
            
           
            <Spacing md="92" />
         
          </div>
         
        </div>
        
       
      </div>
      <Spacing md="200" xl="150" lg="110" />
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}

export { BlogDetails };
