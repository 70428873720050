import React, { useEffect, useState } from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import FunFactSection from '../components/Section/FunFactSection';
import { detailTreatmentData, featureListData, funFactData } from '../detailTreatmentData';
import { useParams } from 'react-router';
import DetailForm from '../components/ContactForm/DetailForm';
import FeaturesSectionStyle5 from '../components/Section/FeaturesSection/FeaturesSectionStyle5';

function TreatmentDetail() {
  const [data, setData] = useState([]);
  const { category, subCategory, detailId } = useParams();

  useEffect(() => {
    // Log individual params to ensure they are correct
    console.log("category:", category);
    console.log("subCategory:", subCategory);
    console.log("detailId:", detailId);

    // Construct the path name
    const pathName = `${category}/${subCategory}/${detailId}`;
    console.log("Constructed pathName:", pathName); // Debugging the path

    // Check the imported data and match it
    const fetchData = () => {
      const filterArray = detailTreatmentData.filter((item) => {
        // Log each comparison for debugging
        console.log(`Comparing pathName: ${pathName} with url: ${item.url}`);
        
        // Normalize both pathName and url (trim spaces, convert to lowercase)
        return item.url.trim().toLowerCase() === pathName.trim().toLowerCase();
      });
      
      console.log("Filtered Data:", filterArray); // Log the result of filtering
      setData(filterArray.length ? filterArray : []);
    };

    fetchData();
  }, [category, subCategory, detailId]);

  useEffect(() => {
    if (data.length > 0) {
      console.log("Data found, setting title:", data[0].title); // Debugging the title
      document.title = data[0].title;
    }
  }, [data]);

  return (
    <>
      <BannerSectionStyle3
        bgUrl={data.length > 0 ? data[0].bgUrl : "/images/tedaviler/breast-aug.jpg"}
        title={data.length > 0 ? data[0].title : "Default Title"}
        subTitle="Western Health Turkey"
      />

      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <FeaturesSectionStyle6
                sectionTitle={data.length > 0 ? data[0].title : "Default Title"}
                data={data}
              />
            </div>
            <div className="col-12 col-md-5">
              <DetailForm title={data.length > 0 ? data[0].title : "Default Title"} />
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={100}
        className="cs_gray_bg_1"
      >
        <FeaturesSectionStyle5
          sectionTitle="Our quality standards"
          data={featureListData}
        />
      </Section>
      
    
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* İçerik */}
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}

export { TreatmentDetail };
