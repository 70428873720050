import React from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import FunFactSection from '../components/Section/FunFactSection';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `

   <p> At the crossroads between the Middle East, Europe, and Asia, Turkey is the 6th most visited destination in the world with the capability to host more than 50 million tourists in a year.

<p>Besides other scopes, Turkey has established itself as a leading medical tourism hub with its world-class surgical performance. In 2021, more than 650,000 foreign patients sought remedy in Turkey with over 400,000 of them from Europe. With its internationally accredited health institutions, private, public, and university hospitals equipped with the most advanced technology in the world, and highly experienced health staff in their fields, Turkey has become top ten destinations for world health tourism.
</p>
<p>From the day of request to the day of departure, by the support of its strong tourism and health infrastructure which were proven during the fight against the pandemic, Turkey offers quality treatment at a low cost with an added taste of tourism.
</p>
<p>Compared to its counterparts, Turkey has more JCI accredited medical centers than any other destination. Many European and American trained doctors provide cardiac, orthopedic, cosmetic, and laser eye surgeries in JCI accredited medical centers as well as dental care and cancer treatment.
</p>
<p>While attending, Western Health Turkey provides you affordable, accessible, available, acceptable, and additional international health services in the delightful city of İzmir, Turkey. Our goal is to serve appropriate treatments, delivered at the appropriate time, in the appropriate place, for the appropriate patient; you. As your medical advisor, we set our goal to prepare your personalized treatment plan through effective and efficient communication.
</p>
<p>Within an environment of trust, our exclusive care packages both help you save 60-75% of treatment costs and time. Without any waiting list, we offer you immediate access to timely medical services operated by our team of professionals. You are our priority.
</p>
<p>From basic screening and cosmetic surgery to high-end surgical procedures and complex specialty care in areas such as Urology and Gynaecology, Bariatrics and Ophthalmology (Eye Surgery) among others, our greatly skilled and experienced medical staff work to deliver the best healthcare experience and treatment for every international patient, through well-supported international medical services.
</p>
<p>From medical consulting before treatment up to a wide range of travel assistance throughout the trip planning such as bookings, visa, transfer, accommodation, personal services and; our fluent native-speaking staff will company you during your journey. We assure you get the right answers.
</p>
<p>Our patient-focused concept is designed to put together the comprehensive and personal treatment that you deserve. During your stay, every detail will be considered as you wish. Also, with efficient scheduling, your healthcare trip may easily be mixed with a holiday in one of the most preferred destinations in the world. We want you to enjoy high-quality healthcare and the chance to explore overseas together.
</p>
<p>Let us help you to be the best version of you!</p>

    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];



function WhyChooseUs() {
  pageTitle('WhyChooseUs');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pages/why.jpg"
        title="Why Choose Us"
        subTitle="Western Health Turkey"
      />
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="Why Choose Us"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
    
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}
export { WhyChooseUs }