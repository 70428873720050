import React from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Section from '../components/Section';
import GallerySectionStyle2 from '../components/Section/GallerySection/GallerySectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
const galleryData = [
  { imgUrl: '/images/galeri/1.jpg' },
  { imgUrl: '/images/galeri/2.jpg' },
  { imgUrl: '/images/galeri/3.jpg' },
  { imgUrl: '/images/galeri/4.jpg' },
  { imgUrl: '/images/galeri/5.jpg' },
  { imgUrl: '/images/galeri/6.jpg' },
  { imgUrl: '/images/galeri/13.jpg' },
  { imgUrl: '/images/galeri/8.jpg' },
  { imgUrl: '/images/galeri/9.jpg' },
  { imgUrl: '/images/galeri/10.jpg' },
  { imgUrl: '/images/galeri/11.jpg' },
  { imgUrl: '/images/galeri/12.jpg' },
  { imgUrl: '/images/galeri/7.jpg' },
  { imgUrl: '/images/galeri/14.jpg' },
  { imgUrl: '/images/galeri/15.jpg' },
  { imgUrl: '/images/galeri/16.jpg' },
  { imgUrl: '/images/galeri/17.jpg' },
  { imgUrl: '/images/galeri/18.jpg' },
  { imgUrl: '/images/galeri/19.jpg' },
  { imgUrl: '/images/galeri/20.jpg' },
  { imgUrl: '/images/galeri/21.jpg' },
  { imgUrl: '/images/galeri/22.jpg' },
  { imgUrl: '/images/galeri/23.jpg' },


  
    
];

function Gallery() {
  pageTitle('Gallery');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pages/galeri.jpg"
        title="Gallery"
        subTitle="Western Health Turkey"
      />
      <Section
        topMd={170}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <GallerySectionStyle2 data={galleryData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
export { Gallery }