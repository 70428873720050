import React from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import Section from '../components/Section';
import FeaturesSectionStyle2 from '../components/Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../components/Section/FunFactSection';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `
      Within Western Health Turkey; the contemporary and dynamic team of professionals with a quarter-century of experience and adequate communication of information, are waiting for you to join a lifetime journey for your health. Our motivation is your satisfaction. Not only your well-being but also the confidentiality of your personal data and information are in reliable hands.<br /><br />
      Our mission is to serve the safest transportation, accommodation, treatment, and tour service supported by quality maximization and risk minimization for our international patients. We ensure that our patients are as well cared for as possible and, receive appropriate advice and input at all stages of the caring process.<br /><br />
      Our vision is to serve a worldwide high-quality health service with the support of advanced technology, ready for all women and men to reach any point on Earth.
    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];

const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];



function WhoWeAre() {
  pageTitle('WhoWeAre');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pages/who-we-are.jpg"
        title="Who We Are"
        subTitle="Western Health Turkey"
      />
    
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Who We Are"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
    
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}
export { WhoWeAre } ;