import React from 'react';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import Section from '../components/Section';
import FaqSection from '../components/Section/FaqSection';
import FunFactSection from '../components/Section/FunFactSection';
import { pageTitle } from '../helpers/PageTitle';


const faqData = [
  {
    title: 'How many times have you carried out the procedure I am having? What are the rates of success, complication, readmission, and infection?',
    content: 'As Western Health Turkey, we have been working in this field for more than a year. You can see a lot of five-star comments about our procedures not only for dental treatments but also for other services such as Gastric Sleeve, Hair Transplantation, and more. The rates of success and complications usually depend on your conditions and the unique structure of your body.',
  },
  {
    title: 'Is the work guaranteed for a certain period of time?',
    content: 'Yes, it is. If you have any problem with your dental treatment, including conditions or material, you can come back without any charge to fix the possible issues.',
  },
  {
    title: 'What aftercare do you provide?',
    content: 'We have an aftercare department that can communicate with you anytime you have any problem or question via video-calling or texting. You can reach our team anytime you want without any hesitation.',
  },
  {
    title: 'What happens if I am unhappy with the results? Who pays for the extra flights, hotel, and remedial work?',
    content: 'Unhappiness is a subjective concept, yet we all care about your happiness. During the procedure, you will have a patient representative, and you should notify them of your concerns. They will intervene in the process as much as possible. In case of any problem or issue, you can return by paying your flights. All other expenses, such as accommodation and remedial work, will be covered by Western Health.',
  },
  {
    title: 'Do you have a complaints system in place? Can I see a copy of it?',
    content: 'You can reach our Patient Satisfaction Department in case of any complaints. They will work to resolve the issue happily.',
  },
  {
    title: 'Who can I contact for advice after the treatment?',
    content: 'The Patient Satisfaction Department is your point of contact. The contact number will be given to you by your patient representative during your visit.',
  },
  {
    title: 'Is the deposit refundable?',
    content: 'Yes. In some cases, the deposit is refundable. These situations include rational issues such as the patient\'s health problem and problems with travel documents.',
  },
  {
    title: 'How will the treatment process begin?',
    content: 'Your surgeon will inspect your body in depth during your consultation before treatment, noting areas to be treated and educating you about the treatment procedures. Your doctor will also describe any potential risks or complications, and you will have the opportunity to ask about any concerns you may have.',
  },
  {
    title: 'Who will be carrying out my treatment and what qualifications do they have?',
    content: 'Assoc. Prof. Selim Bilgin and his team will be your dentist. He is an Assoc. Prof. at one of our qualified state universities and has more than fifteen years of experience in dental treatments.',
  },
  {
    title: 'Do the dental team speak English? If not, will you provide a translator on the day of the procedure?',
    content: 'Yes. The dental team can speak English fluently. Additionally, you will have a patient representative who speaks English very well and will be with you throughout your procedure day.',
  },
  {
    title: 'How does the payment system work?',
    content: 'We generate your price offer based on your treatment information after an online consultation and convey it to you in writing within 24 hours. If you accept our offer, you will be required to sign an agreement outlining all of the services we will provide, as well as your and our duties during your medical travel to Turkey.',
  },
  {
    title: 'Which services are included in the price?',
    content: 'Airport & Hotel Pickup: When you arrive, a VIP transfer will pick you up from the airport and transport you to your hotel and hospital during the treatment process. Accommodation Services: Hotel accommodation services in 4 or 5-star hotels assigned to you are organized and booked by us. Personal Patient Representative: A Personal Patient Representative will be with you throughout your entire process. Lifelong Support/ Aftercare: After you return home, we\'ll keep in touch with you to ensure that you are happy with the treatment. We provide lifelong online support for your care. Free Consultation with Your Doctor: We introduce you to your doctor in this online consultation and provide you the time and chance to ask your doctor any questions you have about your treatment. Medication: All medications to be used during and after the procedure are included in our service and the price. Check Up: Before returning home, you will need to see your doctor for one or more checkups after your treatment. Your doctor will schedule your checkups following your treatment, and your patient representative will accompany you to them.',
  },
  {
    title: 'Do you have any references or testimonials from previous patients?',
    content: 'Yes. We are registered on several legal review sites such as WhatClinic and TrustPilot, where you can see experiences shared by previous patients. We can also provide you with short videos and before-and-after pictures upon request.',
  },
  {
    title: 'How is the consultation with the doctor?',
    content: 'We introduce you to your doctor during the online consultation, providing you the opportunity to ask any questions you may have about your treatment. You and your doctor will review treatment options and decide on the best plan interactively.',
  },
  {
    title: 'Why is the deposit taken?',
    content: 'A deposit payment of 10% of your total offer is required before your trip to Turkey. The remaining balance can be paid upon your arrival. The deposit allows us to make all necessary arrangements for your treatment, including hotel reservations, dentist appointments, and airport transfers. The deposit also secures the special prices offered to you for one year, protecting you from price increases.',
  },
];


const funFactData = [
  { number: '20+', title: 'Years of experience' },
  { number: '95%', title: 'Patient satisfaction rating' },
  { number: '5000+', title: 'Patients served annually' },
  { number: '10+', title: 'Healthcare providers on staff' },
  { number: '22+', title: 'Convenient locations in the area' },
];



function FAQ() {
  pageTitle('FAQ');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pages/faq.jpg"
        title="FAQ"
        subTitle="Western Health Turkey"
      />
    
    <Section topMd={190} topLg={145} topXl={105} bottomMd={200}>
        <FaqSection
          data={faqData}
          sectionTitle="FAQ"
          sectionTitleUp="Western Health Turkey"
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
    
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section>
    </>
  );
}

export { FAQ }