import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/truspilot.png" alt="Western Health Turkey | Trustpilot" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Maryam T.</h3>
                <p className="cs_heading_color mb-0">Rhinoplasty | United Kingdom</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/truspilot.png" alt="Western Health Turkey | Trustpilot" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Rasheen Mahmodian</h3>
                <p className="cs_heading_color mb-0">Gastric Sleeve | Australia</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/truspilot.png" alt="Western Health Turkey | Trustpilot" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Ruth Mensah</h3>
                <p className="cs_heading_color mb-0">Tummy Tuck | United Kingdom</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            I would like to thank Western Health Turkey for taking care of all the details of my operation from the first day until now, great service and beautiful care in the hospital I was very happy with dealing with them, thanks for all the services you provided me

            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            Such amazing staff and service! Yigit was so lovely and kind. I wish there were more ways to thank him. I highly recommend!


            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            
            <p>Such amazing staff and service! Yigit was so lovely and kind. I wish there were more ways to thank him. I highly recommend!</p>

            
            <Rating ratingNumber={5} />
          </div>
        )}
      </div>
    </div>
  );
}
