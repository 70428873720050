import React from 'react';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Section from '../components/Section';
import BlogSectionStyle2 from '../components/Section/BlogSection/BlogSectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';

const blogData = [
  {
    title:
      'Gastric Sleeve',
    thumbUrl: '/images/usttedavi/sleeve.jpg',
    btnText: '',
    href: 'gastric-sleeve',
    socialShare: false,
  },
  {
    title:
      'Gastric Ballon',
    thumbUrl: '/images/usttedavi/baloon.jpg',
    btnText: '',
    href: 'gastric-balloon',
    socialShare: false,
  },
  {
    title:
      'Gastric Bypass',
    thumbUrl: '/images/usttedavi/bypss.jpg',
    btnText: '',
    href: 'gastric-bypass',
    socialShare: false,
  },
  {
    title:
      'Gastric Injection',
    thumbUrl: '/images/usttedavi/injection.jpg',
    btnText: '',
    href: 'gastric-injection',
    socialShare: false,
  },
  
  
  
];

function BariatricSurgeries() {
  pageTitle('Bariatric Surgeries');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/bariatik-cerrahi.jpg"
        title="Bariatric Surgeries"
        subTitle="Western Health Turkey"
      />
      
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <p>&nbsp;</p>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
export { BariatricSurgeries };