import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import IconBoxStyle6 from '../../IconBox/IconBoxStyle6';

export default function FeaturesSectionStyle2({ sectionTitle,  data }) {
  return (
    <div className="cs_shape_wrap">
      <div className="cs_shape_1 cs_position_1" />
      <SectionHeading title={sectionTitle} />
      <Spacing md="85" lg="70" xl="50" />
      <div className="row">
        {data?.map((item, index) => (
          <div  key={index}>
            <IconBoxStyle6 {...item} />
            <Spacing md="85" lg="60" xl="35" />
          </div>
        ))}
      </div>
    </div>
  );
}
