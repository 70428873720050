import React from 'react';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Section from '../components/Section';
import BlogSectionStyle2 from '../components/Section/BlogSection/BlogSectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';

const blogData = [
  {
    title:
      'Breast Augmentation',
    thumbUrl: '/images/usttedavi/breast-aug.jpg',
    btnText: '',
    href: 'breast-augmentation',
    socialShare: false,
  },
  {
    title:
      'Breast Uplift',
    thumbUrl: '/images/usttedavi/breast-up.jpg',
    btnText: '',
    href: 'breast-uplift',
    socialShare: false,
  },
  {
    title:
      'Breast Reduction',
    thumbUrl: '/images/usttedavi/breast-redu.jpg',
    btnText: '',
    href: 'breast-reduction',
    socialShare: false,
  },
  {
    title:
      'Rhinoplasty',
    thumbUrl: '/images/usttedavi/rinoplasti.jpg',
    btnText: '',
    href: 'rhinoplasty',
    socialShare: false,
  },
  {
    title:
      'Liposuction',
    thumbUrl: '/images/usttedavi/lipo.jpg',
    btnText: '',
    href: 'liposuction',
    socialShare: false,
  },
  {
    title:
      'Tummy Tuck',
    thumbUrl: '/images/usttedavi/tummy.jpg',
    btnText: '',
    href: 'tummy-tuck',
    socialShare: false,
  },
  {
    title:
      'Brazilian Butt Lift',
    thumbUrl: '/images/usttedavi/bbl.jpg',
    btnText: '',
    href: 'brazilian-butt-lift',
    socialShare: false,
  },
  {
    title:
      'Arm lift',
    thumbUrl: '/images/usttedavi/arm.jpg',
    btnText: '',
    href: 'armlift',
    socialShare: false,
  },
  {
    title:
      'Thigh lift',
    thumbUrl: '/images/usttedavi/thiglift.jpg',
    btnText: '',
    href: 'thighlift',
    socialShare: false,
  },
  {
    title:
      'Brow lift',
    thumbUrl: '/images/usttedavi/brow-lift.jpg',
    btnText: '',
    href: 'browlift',
    socialShare: false,
  },
  {
    title:
      'Facelift / Necklift',
    thumbUrl: '/images/usttedavi/face-lift.jpg',
    btnText: '',
    href: 'facelift-necklift',
    socialShare: false,
  },
  {
    title:
      'Eyelid',
    thumbUrl: '/images/usttedavi/eye-lid.jpg',
    btnText: '',
    href: 'eyelid',
    socialShare: false,
  },
  {
    title:
      'Gynecomastia',
    thumbUrl: '/images/usttedavi/gynecomastia.jpg',
    btnText: '',
    href: 'gynecomastia#',
    socialShare: false,
  },

  
];

function PlasticSurgeries() {
  pageTitle('Plastic Surgeries');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/plastic.jpg"
        title="Plastic Surgeries"
        subTitle="Western Health Turkey"
      />
      
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <p>&nbsp;</p>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
export { PlasticSurgeries }