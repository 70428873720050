import React from "react";
import Section from "../components/Section";
import DepartmentSectionStyle6 from "../components/Section/DepartmentSection/DepartmentSectionStyle6";
import AboutSectionStyle5 from "../components/Section/AboutSection/AboutSectionStyle5";
import FeaturesSectionStyle5 from "../components/Section/FeaturesSection/FeaturesSectionStyle5";
import BrandsSectionStyle3 from "../components/Section/BrandsSection/BrandsSectionStyle3";
import FaqSectionStyle4 from "../components/Section/FaqSection/FaqSectionStyle4";
import BlogSectionStyle5 from "../components/Section/BlogSection/BlogSectionStyle5";
import TestimonialSection from "../components/Section/TestimonialSection";
import { pageTitle } from "../helpers/PageTitle";
import Caurosel from "../components/Slider/Caurosel";
const departmentData = [
  {
    title: "Plastic Surgeries",
    subTitle:
      "We offer advanced plastic surgeries to enhance your appearance and boost your confidence.",
    iconUrl: "/images/icon/plastic.png",
    href: "procedures/plastic-surgeries",
  },
  {
    title: "Bariatric Surgeries",
    subTitle:
      "Our bariatric surgeries provide effective solutions for long-term weight loss and improved health.",
    iconUrl: "/images/icon/bariatric.png",
    href: "procedures/bariatric-surgeries",
  },
  {
    title: "Dental Treatments",
    subTitle:
      "Experience top-quality dental treatments for a healthy, beautiful smile.",
    iconUrl: "/images/icon/dental.png",
    href: "procedures/dental-treatments",
  },
  {
    title: "Eye Surgeries",
    subTitle:
      "We specialize in eye surgeries to improve vision and treat various eye conditions.",
    iconUrl: "/images/icon/eye.png",
    href: "procedures/eye-surgeries",
  },
  
 
];
const featureListData = [
  {
    iconUrl: "/images/icon/beyaz/1.png",
    title: "Assessments",
    subTitle:
      "Complimentary, non-binding assessments by a diverse range of physicians and dental practitioners.",
  },
  {
    iconUrl: "/images/icon/beyaz/2.png",
    title: "Coordination",
    subTitle:"Timely and hassle-free coordination of your trip to our accredited partner clinics and hospitals across various locations."
    },
  {
    iconUrl: "/images/icon/beyaz/3.png",
    title: "Transparency",
    subTitle: `Unbiased and transparent information.`,
  },
  {
    iconUrl: "/images/icon/beyaz/4.png",
    title: "Quality",
    subTitle:
      "Exceptional quality treatments with reliable warranties.",
  },
  {
    iconUrl: "/images/icon/beyaz/5.png",
    title: "Support",
    subTitle:
      "Continuous support throughout your stay in your chosen destination.",
  },
  {
    iconUrl: "/images/icon/beyaz/6.png",
    title: "Location",
    subTitle:
      "Prime locations near enchanting destinations in city center, allowing you to enjoy your extra time in Turkey - Izmir to the fullest.",
  },
];

const brandData = [
  { imgUrl: "/images/collab/corendon.jpg", imgAlt: "WesternHealthTurkey Corendon" },
  { imgUrl: "/images/collab/emot.jpg", imgAlt: "WesternHealthTurkey Emot Hastames" },
  { imgUrl: "/images/collab/garanti-pay.jpg", imgAlt: "WesternHealthTurkey Garanti Bankası" },
  { imgUrl: "/images/collab/nucle.jpg", imgAlt: "WesternHealthTurkey NucleOss" },
  { imgUrl: "/images/collab/plastic.jpg", imgAlt: "WesternHealthTurkey" },
  { imgUrl: "/images/collab/sun-expres.jpg", imgAlt: "WesternHealthTurkey Sun Express" },
  { imgUrl: "/images/collab/thy.jpg", imgAlt: "WesternHealthTurkey Türk HavaYolları" },
  { imgUrl: "/images/collab/tinaztepe.jpg", imgAlt: "WesternHealthTurkey Tınaztepe Hastanesi" },
  { imgUrl: "/images/collab/tursab.jpg", imgAlt: "WesternHealthTurkey Türsab" },
];
const faqData = [
  {
    title: 'How many times have you carried out the procedure I am having? What are the rates of success, complication, readmission, and infection?',
    content: 'As Western Health Turkey, we have been working in this field for more than a year. You can see a lot of five-star comments about our procedures not only for dental treatments but also for other services such as Gastric Sleeve, Hair Transplantation, and more. The rates of success and complications usually depend on your conditions and the unique structure of your body.',
  },
  {
    title: 'Is the work guaranteed for a certain period of time?',
    content: 'Yes, it is. If you have any problem with your dental treatment, including conditions or material, you can come back without any charge to fix the possible issues.',
  },
  {
    title: 'What aftercare do you provide?',
    content: 'We have an aftercare department that can communicate with you anytime you have any problem or question via video-calling or texting. You can reach our team anytime you want without any hesitation.',
  },
  {
    title: 'What happens if I am unhappy with the results? Who pays for the extra flights, hotel, and remedial work?',
    content: 'Unhappiness is a subjective concept, yet we all care about your happiness. During the procedure, you will have a patient representative, and you should notify them of your concerns. They will intervene in the process as much as possible. In case of any problem or issue, you can return by paying your flights. All other expenses, such as accommodation and remedial work, will be covered by Western Health.',
  },
  {
    title: 'Do you have a complaints system in place? Can I see a copy of it?',
    content: 'You can reach our Patient Satisfaction Department in case of any complaints. They will work to resolve the issue happily.',
  },
  {
    title: 'Who can I contact for advice after the treatment?',
    content: 'The Patient Satisfaction Department is your point of contact. The contact number will be given to you by your patient representative during your visit.',
  },
  {
    title: 'Is the deposit refundable?',
    content: 'Yes. In some cases, the deposit is refundable. These situations include rational issues such as the patient\'s health problem and problems with travel documents.',
  },
  {
    title: 'How will the treatment process begin?',
    content: 'Your surgeon will inspect your body in depth during your consultation before treatment, noting areas to be treated and educating you about the treatment procedures. Your doctor will also describe any potential risks or complications, and you will have the opportunity to ask about any concerns you may have.',
  },
  {
    title: 'Who will be carrying out my treatment and what qualifications do they have?',
    content: 'Assoc. Prof. Selim Bilgin and his team will be your dentist. He is an Assoc. Prof. at one of our qualified state universities and has more than fifteen years of experience in dental treatments.',
  },
  {
    title: 'Do the dental team speak English? If not, will you provide a translator on the day of the procedure?',
    content: 'Yes. The dental team can speak English fluently. Additionally, you will have a patient representative who speaks English very well and will be with you throughout your procedure day.',
  },
  {
    title: 'How does the payment system work?',
    content: 'We generate your price offer based on your treatment information after an online consultation and convey it to you in writing within 24 hours. If you accept our offer, you will be required to sign an agreement outlining all of the services we will provide, as well as your and our duties during your medical travel to Turkey.',
  },
  {
    title: 'Which services are included in the price?',
    content: 'Airport & Hotel Pickup: When you arrive, a VIP transfer will pick you up from the airport and transport you to your hotel and hospital during the treatment process. Accommodation Services: Hotel accommodation services in 4 or 5-star hotels assigned to you are organized and booked by us. Personal Patient Representative: A Personal Patient Representative will be with you throughout your entire process. Lifelong Support/ Aftercare: After you return home, we\'ll keep in touch with you to ensure that you are happy with the treatment. We provide lifelong online support for your care. Free Consultation with Your Doctor: We introduce you to your doctor in this online consultation and provide you the time and chance to ask your doctor any questions you have about your treatment. Medication: All medications to be used during and after the procedure are included in our service and the price. Check Up: Before returning home, you will need to see your doctor for one or more checkups after your treatment. Your doctor will schedule your checkups following your treatment, and your patient representative will accompany you to them.',
  },
  {
    title: 'Do you have any references or testimonials from previous patients?',
    content: 'Yes. We are registered on several legal review sites such as WhatClinic and TrustPilot, where you can see experiences shared by previous patients. We can also provide you with short videos and before-and-after pictures upon request.',
  },
  {
    title: 'How is the consultation with the doctor?',
    content: 'We introduce you to your doctor during the online consultation, providing you the opportunity to ask any questions you may have about your treatment. You and your doctor will review treatment options and decide on the best plan interactively.',
  },
  {
    title: 'Why is the deposit taken?',
    content: 'A deposit payment of 10% of your total offer is required before your trip to Turkey. The remaining balance can be paid upon your arrival. The deposit allows us to make all necessary arrangements for your treatment, including hotel reservations, dentist appointments, and airport transfers. The deposit also secures the special prices offered to you for one year, protecting you from price increases.',
  },
];
const blogData = [
  {
    title: "What is dental anxiety- dentophobia?",
    thumbUrl: "images/blog/kare/b1.jpg",
    href: "/blog/blog01",
  },
  {
    title: "Delicious Smoothie Recipes for Post-Bariatric Surgery: Nourishing Your Body with Our Clinic’s Expert Care",
    thumbUrl: "images/blog/kare/b5.jpg",
    href: "/blog/blog05",
  },
  {
    title: "When Can You Drink Alcohol After Gastric Sleeve Surgery?",
    thumbUrl: "images/blog/kare/b6.jpg",
    href: "/blog/blog06",
  },
];

function Home() {
  pageTitle("Home");
  return (
    <>
      <Caurosel
        title="Western Health Turkey"
        subTitle="Your Trusted Partner in Comprehensive Healthcare, Where Your Well-being is Our Priority, Providing Quality Care and Support for All Your Health Needs."
      />
      <Section topMd={190} topLg={150} topXl={105} className="custom-top-padding" id="departments">
        <DepartmentSectionStyle6
          sectionTitle="Find the Best Treatment  for You"
          sectionTitleUp="Treatments"
          data={departmentData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={100}>
        <AboutSectionStyle5
          imgUrl="/images/afterbefore/before.jpg"
          titleUp="Our results"
          title="Success Stories in Cosmetic Enhancements & Transformations"
          
        />
      </Section>
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={100}
        className="cs_gray_bg_1"
      >
        <FeaturesSectionStyle5
          sectionTitle="Our quality standards"
          data={featureListData}
        />
      </Section>
      
     
     
    
      <Section topMd={185} topLg={145} topXl={105}>
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/tedaviler/ana-ekran-r.jpg"
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={160}
        bottomLg={110}
        bottomXl={80}
      >

        {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
        <BlogSectionStyle5
          sectionTitle="Our Latest Blog"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogData}
        />
      </Section>
      <Section>
        <BrandsSectionStyle3 data={brandData} />
      </Section>
    </>
  );
}
export { Home }