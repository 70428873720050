import React from 'react';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Section from '../components/Section';
import BlogSectionStyle2 from '../components/Section/BlogSection/BlogSectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';

const blogData = [
  {
    title:
      'Cataract',
    thumbUrl: '/images/usttedavi/cataract.jpg',
    btnText: '',
    href: 'cataract-surgery',
    socialShare: false,
  },
  {
    title:
      'Lasik Laser Eye Surgery',
    thumbUrl: '/images/usttedavi/lasik-laser.jpg',
    btnText: '',
    href: 'lasik-laser-eye-surgery',
    socialShare: false,
  },
  {
    title:
      'Trifocal Lens Replacement',
    thumbUrl: '/images/usttedavi/trifocal-lens.jpg',
    btnText: '',
    href: 'trifocal-lens-replacement-surgery',
    socialShare: false,
  },

  
];

function EyeSurgeries() {
  pageTitle('Eye Surgeries');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/lasik-laser.jpg"
        title="Eye Surgeries"
        subTitle="Western Health Turkey"
      />
      
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <p>&nbsp;</p>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
export { EyeSurgeries }