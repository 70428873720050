import React from 'react';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Section from '../components/Section';
import BlogSectionStyle2 from '../components/Section/BlogSection/BlogSectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';

const blogData = [
  {
    title:
      'All On Four All On Six',
    thumbUrl: '/images/usttedavi/all.jpg',
    btnText: '',
    href: 'all-on-four-all-on-six',
    socialShare: false,
  },
  {
    title:
      'Dental Implant',
    thumbUrl: '/images/usttedavi/dental-implant.jpg',
    btnText: '',
    href: 'dental-implants',
    socialShare: false,
  },
  {
    title:
      'Hollywood Smile',
    thumbUrl: '/images/usttedavi/hollywood-smile.jpg',
    btnText: '',
    href: 'hollywood-smile',
    socialShare: false,
  },
  {
    title:
      'Teeth Whitening',
    thumbUrl: '/images/usttedavi/whitening.jpg',
    btnText: '',
    href: 'teeth-whitening',
    socialShare: false,
  },
  {
    title:
      'Zirconium',
    thumbUrl: '/images/usttedavi/zirconium.jpg',
    btnText: '',
    href: 'zirconium-crowns',
    socialShare: false,
  },
  

  
];

function DentalTreatments() {
  pageTitle('Dental Treatments');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/dental.jpg"
        title="Dental Treatments"
        subTitle="Western Health Turkey"
      />
      
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <p>&nbsp;</p>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
export { DentalTreatments }