import React from "react";
import Slider from "react-slick";
import "./CauroselCss.css";
import AppointmentFormStyle2 from "../AppointmentForm/AppointmentFormStyle2";
import backgroundImages from "../../helpers/backgroundImages";

const Caurosel = ({ title, subTitle }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="carousel-container">
      <div className="carousel-bg">
        <Slider {...settings}>
          {backgroundImages.map((image) => (
            <div key={image.id} className="carousel-image-wrapper">
              <img
                src={image.url}
                alt={`Slide ${image.id}`}
                className="carousel-image"
              />
            </div>
          ))}
        </Slider>
      </div>
      <section
        className="cs_hero cs_style_4 cs_bg_filed"
        id="caurosel-section-content"
      >
        <div className="container">
         
          <AppointmentFormStyle2 />
        </div>
      </section>
    </div>
  );
};

export default Caurosel;
