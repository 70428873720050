import React from 'react';
import BannerSectionStyle5 from '../components/Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../components/Section/TeamSection/TeamSectionStyle2';
import Section from '../components/Section';
import { pageTitle } from '../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/wise-and-smile.jpg',
    name: 'Wise and Smile Clinics',  
    category: 'Clinic',
    href: '#',
  },
  {
    imgUrl: '/images/fatma-soysurn.jpg',
    name: 'Dr. Fatma Soysuren Clinic',
    category: 'Clinic',
    href: '#',
  },
 
  {
    imgUrl: '/images/doctors/aysenhoca.jpg',
    name: 'Dr. Ayşen',
    category: 'Doctor',
    href: '#',
  },
  
  {
    imgUrl: '/images/doctors/hakanhoca.jpg',
    name: 'Dr. Hakan',
    category: 'Doctor',
    href: '#',
  },
  
  {
    imgUrl: '/images/doctors/hanifenurok.jpg',
    name: 'Dt. Hanife Nur OK',
    category: 'Doctor',
    href: '#',
  },
  {
    imgUrl: '/images/doctors/atalayakyol.jpg',
    name: 'Dt. Atalay Akyol',
    category: 'Doctor',
    href: '#',
  },
];

function Clinics() {
  pageTitle('Doctors');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/pages/clinic.jpg"
        title="Our contract hospitals"
        subTitle="Western Health Turkey"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
}

export { Clinics }