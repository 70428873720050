import React from 'react';
import Section from '../components/Section';
import FeaturesSectionStyle6 from '../components/Section/FeaturesSection/FeaturesSectionStyle6';
import { pageTitle } from '../helpers/PageTitle';


const featureListData = [
  {
    title: '',
    subTitle: `

<h3> CANCELLATION AND ASSIGNMENT OF THE AGREEMENT </h3>
<p> Western Health has the right to cancel this Agreement unilaterally in line with the goodwill. In case of cancellation of the Agreement by Western Health, the Patient will get a refund for the amount he/she paid except for compulsory taxes, fees and similar expenses arising from the legal obligations and the expenses that have been paid to third parties, and can be documented and cannot be refunded. Again in this context, the cancellation and change rules of the companies related to the flight, railway and navigation tickets and accommodation expenses purchased previously shall be applied as they are. If any, penalties shall be reflected to the passenger, namely the patient.</p>

<p>The Patient will cover the bank transfer fee for refunds that will be made through a bank. Western Health does not have a right for compensation for this. Western Health cannot be held liable for any delay of refund of fees paid by credit card.</p>

<p>If one of the parties violates the Agreement, non-breaching party requests for elimination of the breach of the Agreement within 7 (seven) work days by a written notice via notary public. Non-breaching party has the right to cancel the Agreement if the breach cannot be eliminated within the given period of time.</p>

<p>For the performance of the Agreement, Hermes Clinics has the right to assign the Agreement partially or fully to real and legal persons with whom it is contracted. In such case, the successor for the assigned parts is the related real person or legal entity.</p>

    `,
    iconUrl: 'images/icons/professional.svg',
  },
  
  
  
];




function CancellationAndRefund() {
  pageTitle('Cancellation And Refund');
  return (
    <>
     
     
    
      <Section topMd={175} topLg={25} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle6
          sectionTitle="Cancellation And Refund"
          /*imgUrl="images/about/why_choose_us.jpeg"*/
          data={featureListData}
        />
      </Section>
      
     
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
      
      </Section>
      
    </>
  );
}
export { CancellationAndRefund }